function App() {
    return (
        <div className="comingSoon">
            <div className="foreground">
                <img src="/images/comingsoonfg.png"></img>
            </div>
        </div>
    )
}

export default App;
